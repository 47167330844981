:root {
  --text-color: #242222;
  --heading-color: #242222;
  --bg-color: rgb(255, 255, 255);
  --bg-card: rgba(255, 255, 255, 0.48);
  --bg-img: url('./assets/bg.jpg') no-repeat center center;
  --bg-blur: rgba(255, 255, 255, 0.2);
  --link-color: #333;
  --link-active-color: #2563eb;
  --link-hover-color: #2563eb;
  --para-color: #111;
  --para-gray-color: #3c3838;
  --other-para-color: #534e4e;
  --accent-blue: #2167e9;
  --underline: #fde860;
  --shadow: 0 0 10px 2px #f4ecec6f;
  --line: #ffecec;
  box-sizing: border-box;
}

html[data-theme='dark'] {
  --text-color: #fff;
  --heading-color: #fff;
  --bg-color: #02010e;
  --bg-card: #181718a8;
  --bg-img: #120f16;
  --bg-blur: rgba(0, 0, 0, 0.2);
  --link-color: #a39e9e;
  --link-active-color: #fff;
  --link-hover-color: #fff;
  --para-color: #f7f2f2;
  --para-gray-color: #bcafaf;
  --other-para-color: #ccc;
  --shadow: 0 0 1px 1px #121224;
  --line: #131214;
  --underline: #2563eb;
}

html {
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  min-height: 100vh;
  background: var(--bg-img);
  background-attachment: fixed;
  line-height: 1.7;
}

h1,
h2,
h3,
h4 {
  color: var(--heading-color);
}

a {
  color: var(--link-color);
}

a.logo {
  padding-left: 0 !important;
}

a.logo:first-of-type::after {
  display: none;
}

p {
  color: var(--para-color);
}

img {
  display: block;
  width: 100%;
}

.container {
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
}

.border {
  border: 1px solid red;
}

.text-bg {
  font-size: 5em;
}

.text-md {
  font-style: 4em;
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-checkbox {
  opacity: 0;
  position: absolute;
}

.theme-label {
  cursor: pointer;
  background-color: var(--accent-blue);
  width: 3.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  position: relative;
  border-radius: 100px;
}

.theme-label svg {
  color: #fff;
}

.theme-label .ball {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.theme-checkbox:checked + .theme-label .ball {
  transform: translateX(1.5em);
}

section h1 {
  font-size: 2em;
}

/* spinner */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #2167e9;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.soundwave-container {
  /* border: 1px solid #fff; */
  height: 16px;
  /* width: auto; */
  display: flex;
  align-items: flex-end;
  margin-top: -5px;
}

.bar {
  width: 2px;
  height: 100%;
  background: #1dd05d;
  border-radius: 2px;
  margin: 0 1px;
  animation: equalizer 1.9s steps(20, end) infinite;
}

.bar:nth-child(1) {
  animation-duration: 1.9s;
}
.bar:nth-child(2) {
  animation-duration: 2s;
}
.bar:nth-child(3) {
  animation-duration: 1.7s;
}
.bar:nth-child(4) {
  animation-duration: 2.1s;
}

@keyframes equalizer {
  0% {
    height: 60%;
  }
  4% {
    height: 50%;
  }
  8% {
    height: 40%;
  }
  12% {
    height: 30%;
  }
  16% {
    height: 20%;
  }
  20% {
    height: 30%;
  }
  24% {
    height: 40%;
  }
  28% {
    height: 10%;
  }
  32% {
    height: 40%;
  }
  36% {
    height: 60%;
  }
  40% {
    height: 20%;
  }
  44% {
    height: 40%;
  }
  48% {
    height: 70%;
  }
  52% {
    height: 30%;
  }
  56% {
    height: 10%;
  }
  60% {
    height: 30%;
  }
  64% {
    height: 50%;
  }
  68% {
    height: 60%;
  }
  72% {
    height: 70%;
  }
  76% {
    height: 80%;
  }
  80% {
    height: 70%;
  }
  84% {
    height: 60%;
  }
  88% {
    height: 50%;
  }
  92% {
    height: 60%;
  }
  96% {
    height: 70%;
  }
  100% {
    height: 80%;
  }
}
